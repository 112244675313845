import { CanActivateFn, Router } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class authService {
  constructor(private storageService: StorageService) {}

  public setUserInfo(userData: any) {
    this.storageService.set('access_token', userData?.access_token);
    this.storageService.set('refresh_token', userData?.refresh_token);
    this.storageService.set('profile', userData);
  }

  public getUserInfo() {
    let profile;
    try {
      profile = this.storageService.get('profile');
    } catch (err) {
      console.error('Something went wrong with storage!');
    }
    return profile ?? null;
  }

  public deleteUserInfo() {
    this.storageService.remove('access_token');
    this.storageService.remove('refresh_token');
    this.storageService.remove('profile');
  }
}

export const authGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  return inject(authService).getUserInfo() === null ? router.navigateByUrl('/login') : true;
};

export const loginGuard: CanActivateFn = (route, state) => {
  if (inject(authService).getUserInfo()) {
    inject(Router).navigate(['/inventory']);
    return false;
  } else {
    return true;
  }
};

export const permissionGuard: CanActivateFn = (route, state) => {
  const menu_items = inject(authService).getUserInfo()?.menu_item;
  const requestedRoute = route.routeConfig?.path;
  if (menu_items?.includes(requestedRoute) || menu_items?.includes(requestedRoute?.split('/')[0])) {
    return true;
  } else {
    inject(Router).navigateByUrl('access-denied');
    return false;
  }
};
