import { Injectable } from '@angular/core';
import { StorageType } from '../models/storageType';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storageKey: string = 'AFLIMS';
  constructor() {}

  /**
   * save the storage items in localStorage/sessionStorage
   * @param {Object} content object to store
   */
  private store(content: Object, storageType: StorageType): void {
    if (storageType === StorageType.Local) {
      localStorage.setItem(this.storageKey, JSON.stringify(content));
    } else {
      sessionStorage.setItem(this.storageKey, JSON.stringify(content));
    }
  }

  private retrieve(storageType: StorageType) {
    let item: string | null;

    if (storageType === StorageType.Local) {
      item = localStorage.getItem(this.storageKey);
    } else {
      item = sessionStorage.getItem(this.storageKey);
    }

    return item ? JSON.parse(item) : {};
  }

  /**
   * set the value in localStorage with the key specified
   * @param {string} key name of the key
   * @param {any} value value to save in localstorage
   * @param {StorageType} storageType storage type to save value. By default localStorage
   */
  public set(key: string, value: any, storageType?: StorageType): void {
    if (key !== undefined && key !== null) {
      if (storageType === undefined) {
        storageType = StorageType.Local;
      }

      let storage = this.retrieve(storageType);

      storage[key] = value;
      this.store(storage, storageType);
    }
  }

  /**
   * get the value from localstorage
   * @param {string} key name of key to retrieve
   * @param {StorageType} storageType storage type. By default localStorage
   * @return {any} value
   */
  public get(key: any, storageType?: StorageType): any {
    if (storageType === undefined) {
      storageType = StorageType.Local;
    }

    let storage = this.retrieve(storageType);

    return storage[key];
  }

  public remove(key: string, storageType?: StorageType) {
    if (storageType === undefined) {
      storageType = StorageType.Local;
    }
    let storage = this.retrieve(storageType);
    if (storage[key]) {
      delete storage[key];
      this.store(storage, storageType);
    }
  }
}
